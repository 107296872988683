import React from "react";
import { Popup } from "..";
import { EnFlag, VnFlag } from "../../assets/svg";
import i18n, { languageKeys, languages } from "../../i18n";
import style from "./langPopup.module.less";
import cn from "classnames";
import {localGet, localSet} from "../../helpers";
import {keys} from "../../constants";

const LangPopup = ({ children , fromAuth = false}) => {
  const currentLang = localStorage.getItem("i18nextLng");

  const langItems = [
    {
      key: "vi",
      icon: <VnFlag height={20} width={30} />,
      title: i18n.t(languageKeys.ngon_ngu_tieng_viet),
      lang: languages.tieng_viet,
    },
    {
      key: "en",
      icon: <EnFlag height={20} width={30} />,
      title: i18n.t(languageKeys.ngon_ngu_tieng_anh),
      lang: languages.tieng_anh,
    },
  ];

  const handleChangeLanguage = (val) => {
    const already_logged_in = localGet(keys.already_logged_in);
      if (fromAuth && !already_logged_in) { // nếu chọn từ màn login thì set biến already_logged_in = true để khi login k phải check nữa
          localSet(keys.already_logged_in, true)
      }
      i18n.changeLanguage(val);
      window.location.reload();
  };

  return (
    <Popup
      content={
        <div className={style["popup-lang"]}>
          {langItems.map((item) => (
            <div
              key={item.key}
              className={cn(
                style["item"],
                currentLang === item.lang && style["active"]
              )}
              onClick={() => handleChangeLanguage(item.lang)}
            >
              {item.icon}
              <span className={style["title"]}>{item.title}</span>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </Popup>
  );
};

export default LangPopup;
