import React, { useCallback, useState } from 'react';
import { Form, notification } from 'antd';
import i18n, { languageKeys } from "../../i18n";
import { convertMillisecondToAge } from 'helpers';
import moment from 'moment';
import { DateInput } from './DateInput';
import { useSelector } from 'react-redux';
import { useCheckingRequiredByPartner } from 'pages/QuanLyTiepDon/components/ThongTinBenhNhan/thongTinKhamBenh.hook';

const DateFormItem = ({ label, name, required = true, tabIndex, dateRef, form, readOnly, disabled }) => {

  const { user: userProfile } = useSelector((state) => state.auth);
  const { listRequiredNgaySinh } = useCheckingRequiredByPartner(userProfile);

  const [batBuoc, setBatBuoc] = useState(false);

  const handleSetRequire = useCallback((bool) => {
    setBatBuoc(bool);
  }, []);

  const ruleBirth = () => {
    let rule_arr = [
      {
        required: required,
        message: "",
      },
      {
        validator: async (_, value) => {
          if (value?.length > 3) {
            if (listRequiredNgaySinh?.includes(userProfile.partner_code) && value?.length !== 8) {
              return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_du_ngay_thang_nam)));
            }
            batBuoc && handleSetRequire(false);
            if ([4, 8].includes(value.length)) {
              if (value.length === 8) {
                if (+value.slice(4, 8) < 1900) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }
                let age = convertMillisecondToAge(moment(value, "DDMMYYYY").format("YYYYMMDD"));
                if (age <= 15) {
                  handleSetRequire(true);
                }
                const formatVal = moment(value, "DDMMYYYY");
                const isFutureDay = formatVal.isAfter(moment(), "days");
                if (isFutureDay) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }
                if (Number(value.slice(2, 4)) === 2) {
                  const date = Number(value.slice(0, 2));
                  if (date > 29) {
                    return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                  }
                  if (date === 29 && !formatVal.isValid()) {
                    return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                  }
                }
              }
              if (value.length === 4) {
                if (+value > new Date().getFullYear() || +value < 1900) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }
                let nam = value + "0101";
                let age = convertMillisecondToAge(nam);
                if (age <= 15) {
                  handleSetRequire(true);
                }
                if (age <= 6) {
                  notification.error({ message: i18n.t(languageKeys.noti_vui_long_nhap_du_ngay_thang_nam), placement: "bottomLeft" });
                  return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_du_ngay_thang_nam)));
                }
              }
              return Promise.resolve();
            } else {
              return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
            }
          } else {
            return Promise.reject(new Error(i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)));
          }
        },
      },
    ];
    return rule_arr;
  };

  const handleChangeDate = async (strDate) => {
    form.setFieldValue(name, strDate);
  };

  return (
    <Form.Item label={label} name={name} rules={ruleBirth()}>
      <DateInput
        tabIndex={tabIndex}
        onChange={handleChangeDate}
        ref={dateRef}
        readOnly={readOnly}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default DateFormItem;