import JsBarcode from "jsbarcode";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import i18n, { languageKeys, languages } from "../../../i18n";
import { useSelector } from "react-redux";
import { calcTotal, formatNumberToPrice, getSexFromString,  } from "helpers";
import { getUser } from "ducks/slices/authSlice";
import { enDateFormat } from "constants/keys";

export const stylePhieuMuaThVt = `
#phieu-mua-thuoc-vattu * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-mua-thuoc-vattu .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-mua-thuoc-vattu .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-mua-thuoc-vattu .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 10px; }
#phieu-mua-thuoc-vattu .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-mua-thuoc-vattu .subtitle { margin-block: 0.25rem; font-size: 12px; font-weight: bold; }
#phieu-mua-thuoc-vattu .table * { line-height: 1.2em; }
#phieu-mua-thuoc-vattu .table { page-break-inside:auto; }
#phieu-mua-thuoc-vattu tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-mua-thuoc-vattu thead { display:table-header-group }
#phieu-mua-thuoc-vattu tfoot { display:table-footer-group }
#phieu-mua-thuoc-vattu .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-mua-thuoc-vattu .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-mua-thuoc-vattu .table th, .table td { padding: 5px; }
#phieu-mua-thuoc-vattu .table th { text-align: start; }
#phieu-mua-thuoc-vattu .noBorder2 { border-right: none; }
#phieu-mua-thuoc-vattu .noBorder { border-left: none; }
#phieu-mua-thuoc-vattu .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-mua-thuoc-vattu .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-mua-thuoc-vattu .anotherFoot { display: flex; padding-top: 10px; }
#phieu-mua-thuoc-vattu .anotherFoot > div { width: 50%; text-align: center; }
#phieu-mua-thuoc-vattu .des { font-style: italic; }
#phieu-mua-thuoc-vattu .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-mua-thuoc-vattu .phone-website { display: flex; gap: 24px; align-items: center }
@media print {
   #phieu-mua-thuoc-vattu, #phieu-mua-thuoc-vattu .foot-break { break-inside: avoid; }
   #phieu-mua-thuoc-vattu{page-break-after: always;}
}
`;

const PhieuMuaThVt = ({ data, rid }) => {

  const { benh_vien } = useSelector(getUser);
  const { benh_nhan, HOA_DON } = data

  // useEffect(() => {
  //   if (!!rid && !!benh_nhan.so_phieu) {
  //     JsBarcode(`#barcode-${rid}`)
  //       .options({ font: "OCR-B" })
  //       .CODE128(benh_nhan.so_phieu, {
  //         text: ` `,
  //         fontSize: 10,
  //         width: 1.2,
  //         height: 40,
  //         textMargin: 0,
  //         margin: 0,
  //       })
  //       .render();
  //   }
  // }, [benh_nhan.so_phieu, rid]);

  const TONG_CHI_PHI = HOA_DON.reduce((a, b) => a + (b.SO_LUONG * b.DON_GIA), 0)
  const TONG_THANH_TOAN = HOA_DON.reduce((a, b) => a + (b.SO_LUONG * b.DON_GIA - b.TONG_MIEN_GIAM), 0)

  return Array(2)
    .fill(null)
    .map((_, index) => (
      <div id="phieu-mua-thuoc-vattu" key={index}>
        {/* <div className="header">
          {benh_vien.ANH_DAI_DIEN && <Images imgId={benh_vien.ANH_DAI_DIEN} className="logo" />}

          <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <div className="hospital-name">{benh_vien.TEN_CSKCB}</div>
              <div>
                <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {layDiaChi(benh_vien)}
              </div>
              <div className="phone-website">
                <div>
                  <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {benh_vien.SO_DIEN_THOAI || ""}
                </div>
                <div>
                  <span className="txt-700">Website</span>: {benh_vien?.WEBSITE || ''}
                </div>
              </div>
              <div>
                <span className="txt-700">Email</span>: {benh_vien?.EMAIL || ''}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
              <svg id={`barcode-${rid}`}></svg>
              <div className="sub-barcode-text">
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.ma_kh)}:<b> {benh_nhan.ma_benh_nhan}</b>
                </div>
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.field_So_phieu)}: <b>{benh_nhan.so_phieu}</b>
                </div>
              </div>
            </div>

          </div>
        </div> */}

        <div className="title">
          {i18n.t(languageKeys.title_Phieu_thu_phi_dich_vu).toUpperCase()}
        </div>

        <div className="subtitle" style={{ textAlign: "center", lineHeight: "1em", marginBottom: '1rem' }}>
          ({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})
        </div>

        <div className="subtitle">{i18n.t(languageKeys.thong_tin_khach_hang)}</div>

        <div>

          <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
            <div style={{ display: "flex", gap: "20%" }}>
              <div>
                {" "}
                {i18n.t(languageKeys.ho_va_ten)}: <b> {!!benh_nhan.ten_benh_nhan ? benh_nhan.ten_benh_nhan : 'Khách lẻ'}</b>
              </div>
            </div>

            <div style={{ display: "flex", gap: "20%" }}>
              <div>
                {" "}
                {i18n.t(languageKeys.field_Ngay_sinh)}: {benh_nhan?.ngay_sinh.split("/").pop()}
              </div>
              <div>
                {" "}
                {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
              </div>
            </div>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
            <div>
              {" "}
              {i18n.t(languageKeys.field_So_dien_thoai)}: {benh_nhan.sdt}
            </div>
            <div>
              {" "}
              {i18n.t(languageKeys.field_Doi_tuong)}: {benh_nhan.doi_tuong}
            </div>
          </div>

          <div>
            {" "}
            {i18n.t(languageKeys.dia_chi)}: {benh_nhan.dia_chi}
          </div>

          <div>
            {" "}
            {i18n.t(languageKeys.field_Ly_do_mien_giam)} : {benh_nhan.ly_do_mien_giam}
          </div>

          <table style={{ width: "100%" }} className="table">
            <thead>
              <tr>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ten_thuoc_vat_tu)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.dvt)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.sl)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.tong_tien)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Mien_giam)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
              </tr>
            </thead>

            <tbody>
              {HOA_DON.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                      <td>{i.TEN_VAT_TU}</td>
                      <td>{i.TEN_DVT}</td>
                      <td style={{ textAlign: 'center' }}>{i.SO_LUONG}</td>
                      <td style={{ textAlign: "end" }}>{formatNumberToPrice(i.DON_GIA)}</td>
                      <td style={{ textAlign: "end" }}>{formatNumberToPrice(i.SO_LUONG * i.DON_GIA)}</td>
                      <td style={{ textAlign: "end" }}>{formatNumberToPrice(i.TONG_MIEN_GIAM)}</td>
                      <td style={{ textAlign: "end" }}>{formatNumberToPrice(i.SO_LUONG * i.DON_GIA - i.TONG_MIEN_GIAM)}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>

          <div className="foot-break">

            <div style={{ display: "flex", justifyContent: "space-between", margin: '15px 0 12px' }}>
              <div>
                <b>{i18n.t(languageKeys.tong_tien)}: </b>
                <b>{formatNumberToPrice(TONG_CHI_PHI)}</b>
              </div>
              <div>
                <b>{i18n.t(languageKeys.field_Mien_giam)}: </b>
                <b>{formatNumberToPrice(calcTotal(HOA_DON, "TONG_MIEN_GIAM"))}</b>
              </div>
              <div>
                <b>{i18n.t(languageKeys.vien_phi_thanh_tien)}: </b>
                <b>{formatNumberToPrice(TONG_THANH_TOAN)}</b>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{/*QR*/}</div>

              <div>
                {data?.HINH_THUC_THANH_TOAN?.map((httt, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 40,
                      alignItems: "center",
                    }}
                  >
                    <div>{i18n.t(languageKeys.field_Thanh_toan) + " " + httt.TEN_HINH_THUC_THANH_TOAN}:</div>
                    <div>{formatNumberToPrice(httt.TIEN_BENH_NHAN_DUA)}</div>
                  </div>
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 40,
                    alignItems: "center",
                    borderTop: "1px solid #000",
                    marginTop: 5,
                    paddingTop: 5,
                  }}
                >
                  <b>{i18n.t(languageKeys.tong_tien_kh_dua)}:</b>
                  <b>{formatNumberToPrice(TONG_THANH_TOAN)}</b>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 40,
                    alignItems: "center",
                  }}
                >
                  <b>{i18n.t(languageKeys.tong_can_thanh_toan)}:</b>
                  <b>{formatNumberToPrice(TONG_THANH_TOAN)}</b>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 40,
                    alignItems: "center",
                  }}
                >
                  <b>{i18n.t(languageKeys.so_du_cong_no)}:</b>
                  <b style={{ color: '#f34946' }}>0</b>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="foot-break" style={{ minHeight: "20vh" }}>

          <div className="anotherFoot">
            <div style={{ marginTop: 32 }}>
              <b style={{ fontSize: "12px" }}>{i18n.t(languageKeys.khach_hang)}</b>
              <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
              <div style={{ marginTop: 80, fontWeight: "bold" }}>{data.benh_nhan.ten_benh_nhan}</div>
            </div>

            <div>
              <div style={{ display: "flex", justifyContent: "center", }}>
                {data?.ngay_tao ?
                  <i>
                    {data.gio ? data.gio + ", " : ""}{data.ngay_tao}
                  </i>
                  :
                  <i
                    style={{
                      width: "50%",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    {i18n.language === languages.tieng_viet ? (dayjs().format("HH:mm") +
                      `,  ${i18n.t(languageKeys.common_ngay)} ` +
                      dayjs().format("DD") +
                      `  ${i18n.t(languageKeys.common_thang)} ` +
                      dayjs().format("MM") +
                      `  ${i18n.t(languageKeys.common_nam)} ` +
                      dayjs().format("YYYY"))
                      : (
                        dayjs().format(enDateFormat)
                      )
                    }
                  </i>}
              </div>
              <b style={{ fontSize: "12px" }}>{i18n.t(languageKeys.field_nguoi_ban_thuoc)}</b>
              <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
              <div style={{ marginTop: 80, fontWeight: "bold" }}>{data?.thu_ngan || ""}</div>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default PhieuMuaThVt;

