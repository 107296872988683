import i18n, { languageKeys } from "../../i18n";
import moment from 'moment';
import { Button, notification, Tag, Tooltip } from 'antd';
import { common_post, formatCurrency } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { apis } from '../../constants';
import Table from "components/Table/Table";
import { PrinterOutlined } from "@ant-design/icons";
import style from "./style.module.less";
import ModalPDF from "components/Modal/ModalPDF";

const MedReceiptsHistory = ({ BENH_NHAN_ID }) => {

  const pdfRef = useRef()

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    handleFetchData({})
  }, [BENH_NHAN_ID])


  const handleFetchData = async (props) => {
    setLoading(true);
    try {
      const res = await common_post(apis.layDsPhieuBanVatTu, {
        page: 1,
        limit: 20,
        TRANG_THAI_PHIEU: "XUAT_KHO",
        PHAN_LOAI: 1,
        BENH_NHAN_ID,
        ...props
      });
      if (res && res.status === 'OK') {
        setData(res.result);
        setPagination({
          current_page: res.current_page,
          total: res.total,
        });
      } else {
        notification.error({
          message: i18n.t(languageKeys.fetchFailed),
          placement: 'bottomLeft'
        });
        data[0] && setData([])
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: i18n.t(languageKeys.fetchFailed),
        placement: 'bottomLeft'
      });
    } finally {
      setLoading(false);
    }
  }


  const handlePrintReceipt = (record) => {
    pdfRef.current.openModal(
      { PHIEU_BAN_VAT_TU_ID: record.PHIEU_BAN_VAT_TU_ID },
      apis.layDataHoaDonBanVatTu
    );
  };


  return (
    <>
      <Table
        columns={[
          ...columns,
          {
            dataIndex: "",
            key: "",
            fixed: "right",
            width: 50,
            render: (_, record) => (
              <div onClick={(e) => e.stopPropagation()} className={style['action']}>
                <Button
                  icon={<PrinterOutlined className={style['print']} />}
                  type="link"
                  onClick={() => handlePrintReceipt(record)}
                />
              </div>
            )
          },
        ]}
        dataSource={data}
        scroll={{ x: "max-content", y: `calc(100vh - 265px)` }}
        showPagination
        loading={loading}
        rowKey={'MA_PHIEU'}
        totalResult={pagination.total}
        currentPage={pagination.current_page}
        limit={20}
        onNext={() => handleFetchData({ page: pagination.current_page + 1 })}
        onPrev={() => handleFetchData({ page: pagination.current_page - 1 })}
      // onClickRow={handleClickRow}
      />

      <ModalPDF ref={pdfRef} />
    </>
  );
};

export default MedReceiptsHistory;


const columns = [
  {
    title: i18n.t(languageKeys.field_Ngay_tao),
    dataIndex: "NGAY_GIO",
    width: 150,
    render: (_, record) => `${record.GIO} - ${moment(record.NGAY).format("DD/MM/YYYY")}`
  },
  {
    title: i18n.t(languageKeys.ten_benh_nhan),
    dataIndex: "TEN",
    width: 200,
    render: (TEN) => TEN ?? i18n.t(languageKeys.ban_le)
  },
  {
    title: i18n.t(languageKeys.sdt),
    dataIndex: "SO_DIEN_THOAI",
    width: 105,
  },
  {
    title: i18n.t(languageKeys.field_Bac_si_chi_dinh),
    dataIndex: "TEN_BAC_SI_CHI_DINH",
    width: 170,
  },
  {
    title: i18n.t(languageKeys.field_So_phieu),
    dataIndex: "MA_PHIEU",
    width: 100,
    render: (ma) => <div className="blue-14">{ma}</div>,
  },
  {
    title: i18n.t(languageKeys.trang_thai),
    dataIndex: "TRANG_THAI_PHIEU",
    width: 90,
    render: (status) => status === 'HUY'
      ? <Tag color="red">{i18n.t(languageKeys.status_Da_huy)}</Tag>
      : <Tag color="green">{i18n.t(languageKeys.txt_da_ban)}</Tag>,
  },
  {
    title: i18n.t(languageKeys.tong_tien),
    dataIndex: "TONG_TIEN",
    width: 120,
    render: (_, record) => formatCurrency(Number(record.TONG_THANH_TOAN) + Number(record.TONG_MIEN_GIAM))
  },
  {
    title: i18n.t(languageKeys.field_Mien_giam),
    dataIndex: "TONG_MIEN_GIAM",
    width: 120,
    render: (money) => formatCurrency(money)
  },
  {
    title: i18n.t(languageKeys.thanh_tien),
    dataIndex: "TONG_THANH_TOAN",
    width: 120,
    render: (money) => formatCurrency(money)
  },
  {
    title: i18n.t(languageKeys.field_hinh_thuc_thanh_toan),
    dataIndex: "HINH_THUC_THANH_TOAN",
    width: 160,
    render: (data) => {
      const httt = (data || []).flatMap(i => i.TEN_HINH_THUC_THANH_TOAN)
      return httt?.[1]
        ? (
          <div>
            <span>{httt[0]}</span>{' '}
            <Tooltip title={httt.slice(1).join(', ')}>
              <Tag color="#6576ff">+ {httt.slice(1)?.length}</Tag>
            </Tooltip>
          </div>
        )
        : httt[0]
    }
  },
];